<template>
  <el-main>
    <el-card class="box-card" v-if="vip" style="margin-bottom: 25px">
      <div slot="header" class="clearfix">
        <span>独享额度</span>
      </div>
      <el-table
          :data="tableDataVip"
          style="width: 100%">
        <el-table-column
            prop="platform_name"
            label="平台"
        >
        </el-table-column>
        <el-table-column
            prop="type_cn"
            label="任务类型"
        >
        </el-table-column>
        <el-table-column
            prop="remain"
            label="可用额度"
        >
        </el-table-column>
        <el-table-column
            prop="max_task_count"
            label="最大限额">
        </el-table-column>
      </el-table>
    </el-card>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>部门共享额度</span>
      </div>
      <el-table
          :data="tableData"
          style="width: 100%">
        <el-table-column
            prop="platform_name"
            label="平台"
        >
        </el-table-column>
        <el-table-column
            prop="type_cn"
            label="任务类型"
        >
        </el-table-column>
        <el-table-column
            prop="remain"
            label="可用额度"
        >
        </el-table-column>
        <el-table-column
            prop="max_task_count"
            label="最大限额">
        </el-table-column>
      </el-table>
    </el-card>
  </el-main>
</template>

<script>
export default {
  name: "UserCenter",
  created() {
    let me = this;
    let vip = localStorage.getItem("vip");
    if (vip == 1) {
      me.vip = true;
    }
    Promise.all([me.axios.get('/api/platform/list')
      , me.axios.get('/api/department/list')
    ])
        .then(([platforms, departments]) => {
          platforms.forEach(platform => {
            me.map.platform['id_' + platform.id] = platform.name;
          })
          departments.forEach(department => {
            me.map.department['id_' + department.id] = department.name;
          })
          if(me.vip){
            me.axios.get('/api/user/limits').then(res => {
              me.rawDataVip = res;
            })
          }
          me.axios.get('/api/department/limits').then(res => {
            me.rawData = res;
          })
        })
  },
  data() {
    return {
      map: {
        department: {},
        platform: {},
        type: {
          'id_1': '关键词',
          'id_2': '评论'
        }
      },
      rawData: [],
      rawDataVip: [],
      vip: false
    }
  },
  computed: {
    tableData() {
      let me = this;
      if (!me.rawData) {
        return [];
      }
      return me.rawData.map(val => {
        val['platform_name'] = me.map.platform['id_' + val['platform_id']];
        val['type_cn'] = me.map.type['id_' + val['task_type']];
        return val;
      });
    },
    tableDataVip() {
      let me = this;
      if (!me.rawDataVip) {
        return [];
      }
      return me.rawDataVip.map(val => {
        val['platform_name'] = me.map.platform['id_' + val['platform_id']];
        val['type_cn'] = me.map.type['id_' + val['task_type']];
        return val;
      });
    }
  }
}
</script>

<style scoped>

</style>